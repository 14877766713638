import { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { isNull, omitBy } from 'lodash'

import { GET_CLIENT_CONFIG } from 'graphql/queries'

const defaultClientConfig: ClientConfig = {
  showBillingPortal: false,
  showLocale: false,
  showClientPages: false,
  ssoOrgId: '',
  disableEmailTaskType: false,
  workosOrganizationId: null,
  disablePermissions: false,
  enableSessionTracking: false,
  hrisIntegrationSystem: '',
  showDocusignTab: false,
  showNotificationOptOut: false,
  showApiKeyTab: false,
  showUpnAndEmployeeIdInputs: false,
  enableFilterByTaxonomies: false,
  showDelegable: false,
  showUserTemplateRoleAssignment: false,
  showConfidentialNewHire: false,
  hideExportEmployees: false,
  enableMultipleTaxonomyTermsOnEmployee: false,
  enableSendsafely: false,
  enableSelectHrisIntegrationSystem: false,
  enableDocebo: false,
  disableSingleTaskSurvey: false,
  editorName: null,
  enableEditButton: false,
  showNewReports: false,
  showCalendarResource: false,
  multipleCalendarAccount: false,
  alwaysShowBothDayTypes: false,
  useExternalUsersForRoleAssignments: false,
  excludeSlackSendMessageToUser: false,
  excludeSlackSendMessageToChannel: false,
  excludeSlackChannelInvite: false,
  excludeSlackGroupInvite: false,
  excludeSlackKickUser: false,
}

const useClientConfig = () => {
  const { data, loading } = useQuery<{ clientConfig: ClientConfig }>(
    GET_CLIENT_CONFIG,
    {
      fetchPolicy: 'cache-only',
    },
  )

  return useMemo(() => {
    if (loading) {
      return { ...defaultClientConfig }
    }

    const config = omitBy(data?.clientConfig || {}, isNull)

    return {
      ...defaultClientConfig,
      ...config,
    }
  }, [data, loading])
}

export default useClientConfig
